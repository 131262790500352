'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.devices.controller:DeviceInterfacesCtrl

 # @description

###
class DeviceInterfacesCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    DeviceManager
    $q
    $scope
    $log
    _
  ) ->
    @progress = true
    @type = {}
    @interfaceFields = []
    @model
    @errors = []

    init = =>
      @id = id
      @typeField = DeviceManager.getInterfaceTypeForm()

      @load = load
      @loadInterfaceForm = loadInterfaceForm
      @add = add
      @remove = remove
      @cancel = cancel

      @load()
      @loadInterfaceForm()
    ##########

    add = ->
      @progress = true
      @model.type = @type.type
      DeviceManager.addInterface(@id, @model).then(
        =>
          @load()
        (resp) =>
          console.log 'error'
          @errors = extractErrors(resp.data.errors.children)
          for error in @errors
            $log.debug error , @interfaceForm[error.key]
            @interfaceForm[error.key].$setValidity('external', false)
      )

    remove = (itemId) ->
      @progress = true
      $q.when(DeviceManager.removeInterface(@id, itemId))
        .then =>
          @load()

    load = ->
      DeviceManager.one(@id)
        .then (data) =>
          @items = _.filter data.deviceInterfaces ,(item) -> !item.deletedAt?
          @progress = false
          @form = {}

    loadInterfaceForm = =>
      if @type?
        if @type.type?
          @interfaceFields = DeviceManager.loadFieldsByType(@type.type)

    cancel = ->
      $mdDialog.cancel()

    extractErrors = (errorsArray) ->
      errors = []
      for key, value of errorsArray
        if value.errors?
          errors.push('key': key, 'value': value.errors)
      errors

    approveForm = =>
      for error in @errors
        $log.debug error
        @interfaceForm[error.key].$setValidity('external', true)

    $scope.$watch 'ctrl.type.type', () ->
      loadInterfaceForm()
    , true

    $scope.$watch 'ctrl.model', () ->
      approveForm()
    , true

    init()

angular
  .module('mundoAdmin.devices')
  .controller 'DeviceInterfacesCtrl', DeviceInterfacesCtrl
